<template>
    <div class="mt-5">
      <div class="py-2 px-3 tableHeader">{{ $vuetify.lang.t("$vuetify.candidates") }}</div>
  
      <v-data-table
        :headers="headers"
        :items="formatedList"
        :loading="loading"
        item-key="id"
        :loading-text="$vuetify.lang.t('$vuetify.loading_please_wait')"
        :no-results-text="$vuetify.lang.t('$vuetify.no_data_found')"
        :no-data-text="$vuetify.lang.t('$vuetify.no_data_available')"
        class="elevation-1 "
      >
        <!-- <template v-slot:[`item.name`]="{ item }">
          <span @click="goToProfile(item)" class="cursor-pointer">{{
            item.name
          }}</span>
        </template> -->

        <template v-slot:[`item.image_preview`]>
            <img
                style="height:70px; width:150px;"
                src="@/assets/logo_indeed.png"
            />        
        </template>

        <template v-slot:[`item.rating`]="{ item }">
            <v-icon color="warning" v-for="n in 5" :key="n">{{
              calculateRating(item.rating, n) ? "mdi-star-outline" : "mdi-star"
            }}</v-icon>
        </template>

        <template v-slot:[`item.name`]="{ item }">
            <span>{{
            item.name
          }}</span><br/>
          <span>{{
            item.email
          }}</span>
        </template>

        <template v-slot:[`item.ai_screening_rating`]="{ item }">
          <span>{{
            item.ai_screening_rating ? item.ai_screening_rating + "%" : item.ai_screening_rating
          }}</span>
        </template>

      </v-data-table>
    </div>
  </template>
  
  <script>
  // import { UTC_TO_LOCAL } from "@/helpers";
  import { mapMutations, mapState } from "vuex";
  
  export default {
    name: "job-screening",
    data() {
      return {
        applicationList: [],
        job: {},
        loading: false,
      };
    },
    computed: {
      ...mapState(["profileLinks"]),
  
      headers() {
        return [
            {
              text: this.$vuetify.lang.t("$vuetify.preview"),
              value: "image_preview",
            },
            { text: this.$vuetify.lang.t("$vuetify.name"), value: "name" },
            { text: this.$vuetify.lang.t("$vuetify.invited_on"), value: "invited_on" },
            { text: this.$vuetify.lang.t("$vuetify.completed_on"), value: "completed_on" },
            { text: this.$vuetify.lang.t("$vuetify.interviewers_rating"), value: "rating" },
            { text: this.$vuetify.lang.t("$vuetify.ai_score"), value: "ai_screening_rating" },
            { text: this.$vuetify.lang.t("$vuetify.status"), value: "status" },
        ];
      },
      formatedList() {
        // return this.applicationList.map((application) => {
        //   const {
        //     _id,
        //     created_at,
        //     status,
        //     candidate: { full_name, experience },
        //     candidate,
        //   } = application;
  
        //   return {
        //     id: _id,
        //     status,
        //     name: full_name,
        //     experience: experience || "--",
        //     date: UTC_TO_LOCAL(created_at, "ll"),
        //     candidate_id: candidate._id,
        //   };
        // });
        return this.applicationList
      },
    },
    methods: {
      ...mapMutations(["SET_PROFILE_LINKS"]),
  
      async fetchList() {
        // try {
        //   this.loading = true;
        //   const resp = await this.axios.get(
        //     `/job/${this.$route.params.id}?w=applications.candidate`
        //   );
  
        //   const job = resp.data;
        //   const { applications } = job;
  
        //   this.job = job;
        //   if (this.$route.params.status === "new") {
        //     this.applicationList = applications.filter(
        //       (app) => app.status.toLowerCase() === "new"
        //     );
        //   } else {
        //     this.applicationList = applications;
        //   }
  
        //   this.loading = false;
        // } catch (error) {
        //   console.log(error);
        // }
        this.applicationList = [
            { id: 13132, image_preview: '', name : 'Faisal Wadia', email: 'wadia@gmail.com', invited_on: 'Oct 12, 2022', completed_on: 'Oct 14, 2022', rating: 3, ai_screening_rating: 81, status: 'Completed'},
            { id: 54354, image_preview: '', name : 'Ghumman Jadega', email: 'jadega@gmail.com', invited_on: 'Oct 17, 2022', completed_on: '', rating: '', ai_screening_rating: '', status: 'Viewed'},
            { id: 23432, image_preview: '', name : 'Bhoman Sheeesh', email: 'sheesh@gmail.com', invited_on: 'Oct 11, 2022', completed_on: '', rating: '', ai_screening_rating: '', status: 'Not Viewed'},
            { id: 67677, image_preview: '', name : 'Bhandara', email: 'bhandara@gmail.com', invited_on: 'Oct 18, 2022', completed_on: 'Oct 24, 2022', rating: 4, ai_screening_rating: 40, status: 'Completed'},
            { id: 43857, image_preview: '', name : 'Khurrana', email: 'khurrana@gmail.com', invited_on: 'Oct 12, 2022', completed_on: 'Oct 18, 2022', rating: 3, ai_screening_rating: 69, status: 'Completed'},
        ]
        this.loading = false;
      },
      goToProfile(item) {
        const links = [...this.profileLinks];
        const index = links.findIndex((profile) => profile.id === item.id);
        if (index === -1) links.push(item);
        this.SET_PROFILE_LINKS(links);
        // this.$router.push(`/application/${item.candidate_id}`);
      },
      calculateRating(star, index) {
        return star < index;
      }
    },
    created() {
      this.fetchList();
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .tableHeader {
    letter-spacing: 1px;
    font-weight: bold;
    background-color: #eaeef2;
    border: 1px solid #e6e6f1;
    color: #3dade5;
  }
  .tableSearch {
    background-color: #f7f7f7;
    border: 1px solid #e6e6f1;
    min-height: 70px;
  }
  .table-row {
    background-color: #f7f7f7;
    border: 1px solid #e6e6f1;
    td {
      color: #2f3242;
    }
  }
  </style>
  